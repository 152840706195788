import React from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';
import css from './SectionLandingHero.module.css';

// Section component that's able to show article content
// The article content is mainly supposed to be inside a block
const SectionLandingHero = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    blocks: blocksRaw,
    isInsideContainer,
    options,
    ...otherProps
  } = props;

  const blocks = blocksRaw.filter(b => b.blockName !== 'youtube-block');
  const mediaBlocks = blocksRaw.filter(b => b.blockName == 'youtube-block');

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  const hasBlocks = blocks?.length > 0;

  return (
    <SectionContainer
      id={sectionId}
      className={classNames(css.landingHero, className)}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      <div>
        {hasHeaderFields ? (
          <header className={classNames(css.heroHeader, defaultClasses.sectionDetails)}>
            <Field data={title} className={classNames(defaultClasses.title, css.landingTitle)} options={fieldOptions} />
            <Field data={description} className={classNames(defaultClasses.description, css.heroDescription)} options={fieldOptions} />
            <Field data={callToAction} className={defaultClasses.ctaButton} options={fieldOptions} />
          </header>
        ) : null}
        {hasBlocks ? (
          <div
            className={classNames(defaultClasses.blockContainer, css.articleMain, css.landingContainer, {
              [css.noSidePaddings]: isInsideContainer,
            })}
          >
            <BlockBuilder
              blocks={blocks}
              sectionId={sectionId}
              ctaButtonClass={defaultClasses.ctaButton}
              options={options}
              {...otherProps}
            />
          </div>
        ) : null}
      </div>
      {mediaBlocks.length ? <div>
        <BlockBuilder
          blocks={mediaBlocks}
          sectionId={sectionId}
          ctaButtonClass={defaultClasses.ctaButton}
          options={options}
          {...otherProps}
        />
      </div> : null}
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionLandingHero.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionLandingHero.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionLandingHero;
