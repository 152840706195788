import React from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';
import css from './SectionComparisonTable.module.css';
import {
  alert,
  bowtackle,
  box,
  cardFees,
  checkInvalid,
  check,
  commission,
  cost,
  danger,
  deals,
  ebay,
  facebook,
  magnetAlert,
  magnetEnergy,
  marketplace,
  monetize,
  multipleBox,
  no,
  online,
  retail,
  savingHand,
  saving,
  shipping,
  shoppify,
  specific,
  stars,
  vetted,
} from './../../../../assets/tableIcons/';

// Section component that's able to show article content
// The article content is mainly supposed to be inside a block
const SectionComparisonTable = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    blocks,
    isInsideContainer,
    options,
    leftTitle,
    currentUser,
    listings,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  const hasBlocks = blocks?.length > 0;

  const categories = [
    {
      label: 'Commission',
      icon: commission,
      id: 'commission'
    },
    {
      label: 'Credit Card Fees',
      icon: cardFees,
      id: 'card-fees'
    },
    {
      label: 'Shipping',
      icon: shipping,
      id: 'shipping'
    },
    {
      label: 'Marketplace',
      icon: marketplace,
      id: 'marketplace'
    },
    {
      label: 'Built to Drive Online Traffic to Seller',
      icon: online,
      id: 'online'
    },
    {
      label: 'Vetted Sellers from the Beginning',
      icon: vetted,
      id: 'vetted'
    },
    {
      label: 'Cost to Set Up Retailer with IFRAME Tech',
      icon: cost,
      id: 'cost'
    },
    {
      label: 'Hunting, Fishing, Outdoor Specific',
      icon: specific,
      id: 'specific'
    },
    {
      label: 'Specializes in Used, Closeouts and Deals',
      icon: deals,
      id: 'deals'
    },
    {
      label: 'Built Specifically for Retailers',
      icon: retail,
      id: 'retail'
    },
    {
      label: 'Monetize Current Webtraffic',
      icon: monetize,
      id: 'monetize'
    },
  ];

  const stores = [
    {
      name: 'Bowtackle',
      icon: bowtackle,
      bgColor: '#FF00FF',
      columnColor: '#FF00FF',
      color: '#FFF',
      isPrimary: true,
      values: [
        {
          id: 'commission',
          value: '8.5%',
          icon: check,
        },
        {
          id: 'card-fees',
          value: '0% to Seller',
          icon: check,
        },
        {
          id: 'shipping',
          value: '0% to Seller',
          icon: check,
        },
        {
          id: 'marketplace',
          value: 'Yes - We leverage more than one store\'s inventory',
          icon: multipleBox,
        },
        {
          id: 'online',
          value: 'Yes',
          icon: magnetEnergy,
        },
        {
          id: 'vetted',
          value: 'Yes plus Rating & Reviews',
          icon: stars,
        },
        {
          id: 'cost',
          value: '$97/mo o $997/year',
          icon: saving,
        },
        {
          id: 'specific',
          value: 'Yes',
          icon: check,
        },
        {
          id: 'deals',
          value: 'Yes',
          icon: check,
        },
        {
          id: 'retail',
          value: 'Yes',
          icon: check,
        },
        {
          id: 'monetize',
          value: 'Yes',
          icon: check,
        },
      ],
    },
    {
      name: 'Facebook Marketplace',
      icon: facebook,
      bgColor: '#F9F9F9',
      columnColor: '#E9E9EA',
      color: '#8E8D8D',
      values: [
        {
          id: 'commission',
          value: <p>0% with <span>Fraud Risk</span></p>,
          icon: alert,
        },
        {
          id: 'card-fees',
          value: <p><span>100%</span> to Seller</p>,
          icon: danger,
        },
        {
          id: 'shipping',
          value: <p><span>100%</span> to Seller</p>,
          icon: danger,
        },
        {
          id: 'marketplace',
          value: 'Yes',
          icon: box,
        },
        {
          id: 'online',
          value: <p><span>Not without a fee/ Only within Facebook</span></p>,
          icon: magnetAlert,
        },
        {
          id: 'vetted',
          value: 'No',
          icon: no,
        },
        {
          id: 'cost',
          value: 'Must Have Facebook Account',
          icon: checkInvalid,
        },
        {
          id: 'specific',
          value: 'No',
          icon: no,
        },
        {
          id: 'deals',
          value: 'No',
          icon: no,
        },
        {
          id: 'retail',
          value: 'No',
          icon: no,
        },
        {
          id: 'monetize',
          value: 'No',
          icon: no,
        },
      ],
    },
    {
      name: 'Ebay',
      icon: ebay,
      bgColor: '#E8E8E8',
      columnColor: '#D0D0D0',
      color: '#8E8D8D',
      values: [
        {
          id: 'commission',
          value: <p>10-20% with <span>Fraud Risk</span></p>,
          icon: alert,
        },
        {
          id: 'card-fees',
          value: <p><span>100%</span> to Seller</p>,
          icon: danger,
        },
        {
          id: 'shipping',
          value: <p><span>Depends</span></p>,
          icon: alert,
        },
        {
          id: 'marketplace',
          value: 'Yes',
          icon: box,
        },
        {
          id: 'online',
          value: <p><span>Not without a fee/ Only within Ebay</span></p>,
          icon: magnetAlert,
        },
        {
          id: 'vetted',
          value: 'Sellers Start with No Vetting',
          icon: no,
        },
        {
          id: 'cost',
          value: 'Must Have Ebay Account',
          icon: checkInvalid,
        },
        {
          id: 'specific',
          value: 'No',
          icon: no,
        },
        {
          id: 'deals',
          value: 'No',
          icon: no,
        },
        {
          id: 'retail',
          value: 'No',
          icon: no,
        },
        {
          id: 'monetize',
          value: 'No',
          icon: no,
        },
      ],
    },
    {
      name: 'Shoppify',
      icon: shoppify,
      bgColor: '#FEFEFE',
      columnColor: '#FFF',
      color: '#8E8D8D',
      circleColor: '#FBF7EC',
      logoWidth: '32px',
      values: [
        {
          id: 'commission',
          value: <p>2.6-2.9%</p>,
          icon: alert,
        },
        {
          id: 'card-fees',
          value: <p><span>100%</span> to Seller</p>,
          icon: danger,
        },
        {
          id: 'shipping',
          value: <p><span>Depends</span></p>,
          icon: alert,
        },
        {
          id: 'marketplace',
          value: 'No',
          icon: no,
        },
        {
          id: 'online',
          value: 'No',
          icon: no,
        },
        {
          id: 'vetted',
          value: <p><span>Rating System and Reviews</span></p>,
          icon: alert,
        },
        {
          id: 'cost',
          value: <p><span>$1-10,000</span></p>,
          icon: savingHand,
        },
        {
          id: 'specific',
          value: 'No',
          icon: no,
        },
        {
          id: 'deals',
          value: 'No',
          icon: no,
        },
        {
          id: 'retail',
          value: <p><span>Custom Only</span></p>,
          icon: alert,
        },
        {
          id: 'monetize',
          value: <p><span>Custom Only</span></p>,
          icon: alert,
        },
      ],
    }
  ];

  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      {hasHeaderFields ? (
        <header className={defaultClasses.sectionDetails}>
          <Field data={title} className={defaultClasses.title} options={fieldOptions} />
          <Field data={description} className={defaultClasses.description} options={fieldOptions} />
          <Field data={callToAction} className={defaultClasses.ctaButton} options={fieldOptions} />
        </header>
      ) : null}
      {hasBlocks ? (
        <div
          className={classNames(defaultClasses.blockContainer, css.articleMain, {
            [css.noSidePaddings]: isInsideContainer,
          })}
        >
          <BlockBuilder
            blocks={blocks}
            sectionId={sectionId}
            ctaButtonClass={defaultClasses.ctaButton}
            options={options}
            currentUser={currentUser}
            listings={listings}
          />
        </div>
      ) : null}
      <div className={css.tableContainer}>
        <table>
          <thead>
            <tr>
              <th className={css.headerTable}>Marketplace Comparison</th>
              {categories.map(category => {
                return <th key={category.id} className={css.categoryCell}>
                  <p><img src={category.icon}/></p>
                  <p>{category.label}</p>
                </th>;
              })}
            </tr>
          </thead>
          <tbody>
            {stores.map(store => {
              let style = {};
              let columnStyle = {};
              let circleStyle = {};
              let logoStyle = {};
              if (!store.isPrimary) {
                style = {
                  backgroundColor: store.bgColor,
                  color: store.color
                };

                columnStyle = { backgroundColor: store.columnColor, color: '#4b4c4c', marginTop: '30px' };
                circleStyle = { backgroundColor: store.circleColor || '#FFF' };
                logoStyle = { width: store.logoWidth || '42px' };
              }
              return <tr key={store.name} className={classNames({[css.primaryRow]: store.isPrimary})}>
                <td style={columnStyle}>
                  <p className={css.storeIcon} style={circleStyle}><img style={logoStyle} src={store.icon}/></p>
                  <p>{store.name}</p>
                </td>
                {store.values.map(value => {
                  return <th key={value.id} style={style}>
                    <p><img src={value.icon}/></p>
                    <p>{value.value}</p>
                  </th>;
                })}
              </tr>;
            })}
          </tbody>
        </table>
      </div>
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionComparisonTable.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionComparisonTable.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionComparisonTable;
