import React from 'react';
import { func, node, object, shape, string, array } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';
import { CategoryListingCard, ListingCard, SearchListingCard } from '../../../../components';

import css from './BlockStores.module.css';
import StoreCard from '../../../../components/ListingCard/StoreCard';

const FieldMedia = props => {
  const { className, media, sizes, options } = props;
  const hasMediaField = hasDataInFields([media], options);
  return hasMediaField ? (
    <div className={classNames(className, css.media)}>
      <Field data={media} sizes={sizes} options={options} />
    </div>
  ) : null;
};

const BlockStores = props => {
  const {
    blockId,
    className,
    rootClassName,
    mediaClassName,
    textClassName,
    ctaButtonClass,
    title,
    text,
    callToAction,
    media,
    responsiveImageSizes,
    options,
    currentUser,
    stores
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const hasTextComponentFields = hasDataInFields([title, text, callToAction], options);

  return (
    <BlockContainer id={blockId} className={classes}>
      <FieldMedia
        media={media}
        sizes={responsiveImageSizes}
        className={mediaClassName}
        options={options}
      />
      <div className={classNames(textClassName, css.listingsContainer)}>
        {stores && stores?.map((store, index) => (
          <StoreCard store={store} key={store?.id?.uuid}/>
          // <SearchListingCard
            // key={l.id.uuid}
            // currentUser={currentUser}
            // listing={l}
            // renderSizes={cardRenderSizes}
            // hideSavedListing={true}
            // onUpdateSavedListings={() => {}}
            // setActiveListing={setActiveListing}
          // />
          // <Field data={{content: link, fieldType: 'markdown'}} options={options} key={`link_${index}`}/>
        ))}
      </div>
    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockStores.defaultProps = {
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
  title: null,
  text: null,
  callToAction: null,
  media: null,
  responsiveImageSizes: null,
  options: null,
  currentUser: null,
};

BlockStores.propTypes = {
  blockId: string.isRequired,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
  title: object,
  text: object,
  callToAction: object,
  media: object,
  responsiveImageSizes: string,
  options: propTypeOption,
  currentUser: object,
};

export default BlockStores;
